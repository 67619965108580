import {
  createComponentAdmin,
  PublishesTo,
  SubscribesTo,
  OptionalString,
  DeriveInstructionType,
  type SchemaTypeHelper,
} from '@backstage-components/base';
import {Static, TObject, TOptional, TString, Type} from '@sinclair/typebox';

export const reactName = 'PublicAccessCode';
export const name = 'Public Access Code';
export const description = reactName;

const validationMessagesSchema = Type.Object(
  {
    required: OptionalString({title: 'Error message for a required field.'}),
  },
  {
    default: {
      required: 'This field is required',
    },
  }
);

function getValidationMessagesSchema(
  title: string,
  description?: string
): TObject<{
  required: TOptional<TString>;
}> {
  return {...validationMessagesSchema, title, description};
}

export const schema = Type.Object({
  title: OptionalString({
    title: 'Public Access Code Title',
    default: 'WELCOME',
  }),
  titleColor: OptionalString({
    title: 'Title Color',
    default: 'black',
  }),
  subtitle: OptionalString({
    title: 'Public Access Code Subtitle',
    default: 'Enter your full name and event password',
  }),
  subtitleColor: OptionalString({
    title: 'Subtitle Color',
    default: 'black',
  }),
  buttonLabel: OptionalString({
    title: 'Submit Button Label',
    default: 'SUBMIT',
  }),
  nameInputPlaceholder: OptionalString({
    title: 'Name Input Placeholder Text',
    default: 'Enter your full name',
  }),
  codeInputPlaceholder: OptionalString({
    title: 'Code Input Placeholder Text',
    default: 'Enter Password',
  }),
  nameInputValidation: getValidationMessagesSchema('Name Input Error Messages'),
  codeInputValidation: getValidationMessagesSchema('Code Input Error Messages'),
  codeErrorMessage: OptionalString({
    title: 'Invalid Passcode Error Message',
    description: 'Message displayed when an incorrect passcode is entered',
    default: 'Invalid Passcode',
  }),
});

const privateSchema = Type.Object({
  // The schema for `passwords` is tightly coupled to the GQL resolver
  // definitions for `verifyPublicPasscode`. Changes to the schema may require
  // changes to the resolver be made in a backwards compatible way (because some
  // data with the old structure may already be published)
  passwords: Type.Array(
    Type.String({
      description: 'enter a password',
    }),
    {
      title: 'Passwords',
      description:
        'Enter one or more passwords that will be valid for the public access code module. Passwords are not case sensitive and spaces are ignored',
      minItems: 1,
      default: [''],
    }
  ),
});

export const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    sections: [
      [
        'Properties',
        [
          {
            'ui:template': 'accordion',
            sections: [
              [
                'General',
                [
                  'passwords',
                  'title',
                  'titleColor',
                  'subtitle',
                  'subtitleColor',
                  'buttonLabel',
                ],
              ],
              [
                'Localization',
                [
                  'nameInputPlaceholder',
                  'codeInputPlaceholder',
                  'nameInputValidation',
                  'codeInputValidation',
                  'codeErrorMessage',
                ],
              ],
            ],
          },
        ],
      ],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [
              // prettier-ignore
              ['Custom Styles', ['styleAttr']],
            ],
          },
        ],
      ],
      ['Animations', []],
    ],
  },
  titleColor: {
    'ui:widget': 'color',
  },
  subtitleColor: {
    'ui:widget': 'color',
  },
  passwords: {
    items: {
      'ui:options': {
        // Hide the "passwords-${index}" label but display the `description`
        displayDescription: true,
        label: false,
      },
    },
  },
};

type DefaultFieldData = Static<typeof schema> & Static<typeof privateSchema>;

export const defaultFieldData: DefaultFieldData = {
  passwords: privateSchema.properties.passwords.default,
  title: schema.properties.title.default,
  titleColor: schema.properties.titleColor.default,
  subtitle: schema.properties.subtitle.default,
  subtitleColor: schema.properties.subtitleColor.default,
  buttonLabel: schema.properties.buttonLabel.default,
  nameInputValidation: validationMessagesSchema.default,
  codeInputValidation: validationMessagesSchema.default,
  nameInputPlaceholder: schema.properties.nameInputPlaceholder.default,
  codeInputPlaceholder: schema.properties.codeInputPlaceholder.default,
  codeErrorMessage: schema.properties.codeErrorMessage.default,
};

export const PublicAccessCodeInstructionSchema = Type.Union([
  PublishesTo({
    topic: `${reactName}:verify`,
    description:
      'Requests the given public access code be verified for the show',
    meta: {
      passCode: Type.String({
        description: 'Public access code to be verified',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which access code will be checked',
      }),
      name: Type.String({
        description: 'User name displayed in chat',
      }),
      moduleId: Type.String({
        description: 'User unique identifier of the module',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:success`,
    description: 'Access code was successfully verified',
    meta: {
      attendee: Type.Object({
        id: Type.String({
          description: 'Unique identifier for the verified Attendee',
        }),
        name: Type.String({
          description: 'Name of the attendee, if known',
        }),
        email: Type.Union([Type.Null(), Type.String()], {
          description: 'Attendee email address if known, null otherwise',
        }),
        chatTokens: Type.Array(
          Type.Object({
            token: Type.String({
              description: 'Token used to authenticate with getstream API',
            }),
          })
        ),
        tags: Type.Array(Type.String(), {
          description: 'Tags associated with the attendee',
        }),
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:failure`,
    description: 'Access code could not be verified',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason the code could not be verified',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-failure`,
    description: 'Indicates an unsuccessful verification has occurred.',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason the code could not be verified',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was unable to be verified.',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-success`,
    description: 'Indicates a successful verification has occurred.',
    meta: {
      attendeeId: Type.String({
        description: 'Unique identifier for the verified Attendee',
      }),
      attendeeName: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee name if available, null otherwise',
      }),
      attendeeEmail: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee email address if available, null otherwise',
      }),
      attendeeTags: Type.String({
        description:
          'Comma separated list of tags associated with the attendee',
      }),
      enteredPassCode: Type.String({
        description: 'Passcode used for authentication',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was verified.',
      }),
    },
  }),
  SubscribesTo({
    topic: `${reactName}:reset`,
    description:
      'Resets the access code component (local only) clearing the form',
  }),
]);
export type InstructionSchema = DeriveInstructionType<
  typeof PublicAccessCodeInstructionSchema
>;

export const ComponentDefinition = createComponentAdmin({
  id: '19064a06-87a3-4e3d-b521-c15a9aa1039c',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  privateSchema,
  uiSchema,
  instructions: PublicAccessCodeInstructionSchema,
  category: 'preset',
})
  .withAnalyticsInstructionMask((instruction) => {
    switch (instruction.type) {
      case 'PublicAccessCode:on-success':
        return {
          type: 'PublicAccessCode:on-success',
          meta: {
            about: instruction.meta.about,
            attendeeId: instruction.meta.attendeeId,
            attendeeName: instruction.meta.attendeeName,
            enteredPassCode: hashCode(instruction.meta.enteredPassCode),
          },
        };
      case 'PublicAccessCode:success':
        return {
          type: instruction.type,
          meta: {about: instruction.meta.about},
        };
      case 'PublicAccessCode:verify':
        return {
          type: 'PublicAccessCode:verify',
          meta: {
            about: instruction.meta.about,
            showId: instruction.meta.showId,
          },
        };
      default:
        return instruction;
    }
  })
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition>;

/**
 * Returns a 32bit integer hash code from a string
 * @see https://stackoverflow.com/a/8831937
 */
function hashCode(value?: string): string {
  let hash = 0;
  if (typeof value !== 'undefined') {
    for (let i = 0, len = value.length; i < len; i++) {
      const chr = value.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
  }
  return `${hash}`;
}
